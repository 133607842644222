<template>
    <div>
        <a
            v-if="itemType === 'vin'"
            @click.prevent="openSlideout">
            {{ item.vin }}
        </a>
        <div
            v-else-if="itemType === 'features'">
            <truncate-text
                :value="item.features ?? ''"
                :characters="70"
                :custom-function="true"
                @callback="openSlideout" />
        </div>
        <div
            v-else-if="itemType === 'description'">
            <truncate-text
                :value="item.description ?? ''"
                :characters="70"
                :custom-function="true"
                @callback="openSlideout" />
        </div>
        <a
            v-else
            @click.prevent="openSlideout">
            View
        </a>
    </div>
</template>

<script>
import TruncateText from '@/components/globals/TruncateText';

export default {
    components: {
        TruncateText
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        itemType: {
            type: String,
            default: ''
        }
    },
    methods: {
        openSlideout() {
            this.$emit('callback');
        }
    }
};
</script>>